import logo from './logo.svg';
import {useState, useEffect} from 'react'
import './App.css';

function App() {
  const [infiniteList, setInfiniteList] = useState([
    "HTML",
    "CSS",
    "JS",
    "SSG",
    "webdev",
    "animations",
    "UX/UI",
    "React",
    "Redux",
    "SQL",
    "TypeScript",
    "NodeJS",
    "Express",
    "VueJS",
    
  ]);
const [animateScroller,setAnimateScroller] = useState(false);
function addAnimation(){
  const scroller = document.querySelectorAll('.scroller');
  const scrollerInner = document.querySelector('.scroller__inner');
  const scrollerContent = Array.from(scrollerInner.children);
  scrollerContent.forEach(item=>{
    const  duplicatedItem = item.cloneNode(true);
    duplicatedItem.setAttribute('aria-hidden', true);
    scrollerInner.appendChild(duplicatedItem);
  })
}



// if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches){
//   addAnimation();
// }
// function addAnimation(){
//   console.log(
//     'setting scroller'
//   )
//   scroller.forEach((scroller) =>{
//     scroller.setAttribute('data-animated', true);
//   })
// };

// addAnimation();
useEffect(()=>{
  if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches){
  setAnimateScroller(true);
  addAnimation();
  }
},[]);

  return (
    <>
<div className='scroll-watcher'></div>
<header className="primary-header content-grid">
  <div className="primary-header__layout full-width">
    {/* <svg class="logo" id="logo-10" width="163" height="42" viewBox="0 0 163 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.21 17.719C33.348 17.719 34.054 19.082 34.054 20.782C34.054 22.584 33.2 23.875 30.223 23.875C27.265 23.875 26.41 22.659 26.41 20.782C26.41 18.935 27.342 17.719 30.21 17.719Z" class="ccustom" fill="#394149"></path>
      <path d="M63.004 17.719C66.142 17.719 66.848 19.082 66.848 20.782C66.848 22.584 65.993 23.875 63.017 23.875C60.06 23.875 59.204 22.659 59.204 20.782C59.204 18.935 60.136 17.719 63.004 17.719Z" class="ccustom" fill="#394149"></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.42676 38.5V3.047H161.005V38.5H2.42676ZM78.0048 36.5H159.005V5.047H78.0048V36.5ZM30.225 26.893C35.796 26.893 38.138 24.49 38.138 20.737C38.138 16.923 35.5 14.656 30.225 14.656C25.031 14.656 22.297 17.118 22.297 20.737C22.297 24.821 25.165 26.893 30.225 26.893ZM10.856 14.926V26.713H22.1V23.574H14.924V14.926H10.856ZM50.722 26.713L50.617 25.571C49.2561 26.5422 47.5997 27.0096 45.932 26.893C43.425 26.893 41.337 26.142 40.166 24.626C39.3936 23.5192 39.0036 22.1906 39.055 20.842C39.0111 19.5217 39.4146 18.2253 40.2 17.163C41.385 15.692 43.533 14.716 46.731 14.716C50.41 14.716 52.572 15.842 53.502 17.734C53.7016 18.1796 53.8189 18.6576 53.848 19.145H49.956C49.8579 18.8488 49.6754 18.5878 49.431 18.394C48.6557 17.88 47.7299 17.6423 46.803 17.719C46.2593 17.6531 45.7077 17.7033 45.1849 17.8664C44.6621 18.0295 44.1798 18.3017 43.77 18.665C43.3046 19.302 43.0861 20.0862 43.155 20.872C43.101 21.6217 43.3019 22.3677 43.725 22.989C44.1385 23.3743 44.6301 23.6661 45.1663 23.8449C45.7026 24.0236 46.2709 24.0851 46.833 24.025C47.9493 24.1158 49.0576 23.7715 49.926 23.064C50.1125 22.8308 50.2411 22.5566 50.301 22.264H46.113V19.731H53.95V26.713H50.722ZM63.019 26.893C68.589 26.893 70.932 24.49 70.932 20.737C70.932 16.923 68.2899 14.656 63.019 14.656C57.825 14.656 55.091 17.118 55.091 20.737C55.091 24.821 57.959 26.893 63.019 26.893Z" class="ccustom" fill="#394149"></path>
      <path d="M84.857 26.668V14.881H87.757V26.668H84.857Z" class="ccustom" fill="#394149"></path>
      <path d="M102.649 21.743C101.914 22.704 100.649 23.143 98.866 23.154C98.49 23.154 93.505 23.139 93.505 23.139V26.668H90.667V14.881H98.867C99.5242 14.8115 100.189 14.8812 100.817 15.0855C101.446 15.2899 102.024 15.6243 102.515 16.067C103.163 16.9112 103.478 17.9638 103.401 19.025C103.467 19.9902 103.202 20.9491 102.649 21.743ZM100.322 17.9C100.022 17.45 99.375 17.2 98.28 17.2H93.49V20.788H98.249C99.42 20.788 100.037 20.548 100.322 20.082C100.491 19.7537 100.564 19.384 100.532 19.016C100.557 18.6322 100.485 18.2483 100.322 17.9Z" class="ccustom" fill="#394149"></path>
      <path d="M111.764 26.848C106.418 26.848 104.782 24.848 104.782 22.869H107.815C107.815 23.845 109.151 24.581 111.899 24.581C114.631 24.581 115.352 24.1 115.352 23.319C115.352 22.448 114.812 22.178 112.274 21.893C111.774 21.848 110.413 21.683 109.902 21.608C106.302 21.202 104.916 20.136 104.916 18.229C104.916 16.217 106.944 14.701 111.343 14.701C116.118 14.701 117.95 16.578 117.95 18.53H114.95C114.95 17.539 113.824 16.968 111.166 16.968C108.433 16.968 107.848 17.343 107.848 18.079C107.848 18.845 108.448 19.085 111.348 19.43C112.009 19.521 113.48 19.686 113.885 19.73C116.949 20.045 118.33 21.156 118.33 23.123C118.325 25.466 116.223 26.848 111.764 26.848Z" class="ccustom" fill="#394149"></path>
      <path d="M126.87 26.833C122.952 26.833 120.399 25.286 120.399 22.033V14.881H123.266V22.073C123.266 23.56 124.483 24.55 126.99 24.55C129.257 24.55 130.609 23.62 130.609 22.073V14.881H133.447V22.3C133.447 25.257 131.074 26.833 126.87 26.833Z" class="ccustom" fill="#394149"></path>
      <path d="M148.987 26.668V18.8L145.308 26.668H142.516L138.942 18.8V26.668H136.242V14.881H139.9L143.954 23.86L148.098 14.881H151.698V26.668H148.987Z" class="ccustom" fill="#394149"></path>
    </svg> */}
<svg width="90px" height="90px" className="logo" id="logo-10" viewBox="-6.34 0 94.461 94.461" xmlns="http://www.w3.org/2000/svg"><defs><style></style></defs><path d="M40.869 84.942L8.1 66.177V28.329h.046L0 23.615v47.231l40.869 23.615z" class="a"/><path d="M40.87 47.231L16.385 33.089h-.046v28.329l24.531 14.05z" class="a"/><path d="M81.784 23.615L40.869 0 0 23.615l8.146 4.714 32.723-18.9 32.814 18.854 8.1-4.668z" class="b"/><path d="M65.445 33.043l-24.53-14.142-24.531 14.187L40.869 47.23z" class="b"/><path d="M40.869 47.23l24.576-14.187zM73.683 28.283l8.1-4.668zM81.784 23.615l-8.1 4.668-8.238 4.76L40.869 47.23v28.238l24.576-14.1v-9.332l-16.476 9.379v-9.517l24.714-14.05v28.329L40.869 84.942v9.519l40.915-23.615z" class="c"/></svg>
    <nav>
      <ul>
        <li><a href="#">Домой</a></li>
        <li><a href="#">О Нас</a></li>
        <li><a href="#">Блог</a></li>
        <li><a href="#">Контакты</a></li>
      </ul>
    </nav> 
  </div>
</header>

<main className="flow content-grid">
<img className='hero-image full-width animation-image' src="https://media.cntraveler.com/photos/6363ee169f831a31918739ee/1:1/w_2000,h_2000,c_limit/Giribaldi%20Lift%20Company%20Bar%20and%20Grill_Mitch_Winton.jpg"/>
  <section className="full-width section-padding-hero flow content-grid">
    {/* <img className='hero-image full-width ' src="https://media.cntraveler.com/photos/6363ee169f831a31918739ee/1:1/w_2000,h_2000,c_limit/Giribaldi%20Lift%20Company%20Bar%20and%20Grill_Mitch_Winton.jpg"/>
    <div className='hero-text-content content-grid'> */}
    <h1 className='site-title'>Grid for layouts with Animations Demo!</h1>
    {/* <p >It can be useful to have a system in place that's easy to go full-width, or have a "breakout" section for calls
      to
      action or blockquotes, or other things you want to call attention to.</p> */}
      <p>Было бы отлично построить layout с  CSS Grid, с исользованием которого было бы легко менять размер и позиции элементов сраницы с full-size, на breakout или content.</p>
    {/* <p className='breakout call-to-action-hero'>Traditionally, we use a container or wrapper, which we need to close, and then open again, but thanks to grid,
      and
      with the recent additions of subgrid to all the evergreen browsers, maybe there's a better way to do things?</p> */}
      <p className='breakout call-to-action-hero'>Традиционно, нам приходилось исользовать оболочки из контейнеров(wrappers), которые нам необходимо было закрывать, возможно есть другой интересный вариант!</p>
    {/* <p>It has it's downsides, as the code is more complex, and it's not as obvious how it works when we're so used to
      the
      old pattern.</p> */}
      <p>У него есть недостаток, изначальная настройка и код немного сложнее и не сразу понятно как он работает если мы привыкли к старой схеме!</p>
    {/* <q className='quote'>That said, we shouldn't limit ourselves just because we are used to working in a specific way.</q> */}
    <q className='quote'>Тем не менее, нам не следует ограничивать себя и работать только в одном стиле!(данный блок имеет класс quote, что дает ему меньшую ширину чем стандатный content и особый цвет BG)</q>
    {/* </div> */}
  </section>

  <section className="full-width bg-primary section-padding flow content-grid ">
    {/* <h2>This section has a different background-color</h2> */}
    <h2>У этой секции другой цвет на background!</h2>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam voluptates vitae reiciendis corporis
      molestias aspernatur vero eaque obcaecati neque dolores veritatis repudiandae voluptas consectetur voluptatum
      nobis maiores doloremque, nostrum deleniti!</p>
    <q className='quote'>Aspernatur, dignissimos. Quaerat corporis, itaque porro dolore ea a placeat molestiae nihil voluptatem veniam
      facere, corrupti in vel, voluptates enim soluta sint dolores? Quaerat laboriosam iusto voluptatem accusantium
      ex
      alias?</q>
  </section>

  {/* <h2>Back to normal</h2> */}
  <section className='scroll-reveal full-width flow content-grid ' >
    <h2 ><span className='animation-scroll-reveal' >Вернулись обратно к стилю без BG!</span></h2>
    <p ><span className='animation-scroll-reveal'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Architecto nam at praesentium cumque rem molestias
      earum
      officia neque nemo sunt laboriosam vero eum optio, saepe dolorem exercitationem quod asperiores iste?</span></p>
    <p className='breakout call-to-action'>В данном случае блок текста шире стандартного размера контента (content-grid) и выделен особым цветом!</p>
    <p>Error nulla doloribus ex, eius voluptates saepe, in quae ea nesciunt rem possimus obcaecati consequuntur
      deleniti.
      Tempore deleniti deserunt harum, distinctio, culpa atque numquam ducimus repellat fugit iusto eveniet velit.</p>
  </section>

  <section className="content-grid full-width bg-secondary section-padding flow ">
  {/* <h3>Images as full-bleed works too</h3> */}
  <h3>Картинки по всей ширине также легко настроить выставив класс full-width!</h3>
  {/* <img className='full-width' src="https://images.unsplash.com/photo-1523712999610-f77fbcfc3843?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8YXV0dW1uJTIwZm9yZXN0fGVufDB8fDB8fHww" alt=""/> */}
  <img className='full-width animation-snowboarder-image' src="https://www.aspensnowmass.com/-/media/aspensnowmass---old-dont-use/events/xgames/2015_x_games_sh.jpg" alt=""/>

  <p>Exercitationem dolorum animi at autem tempore omnis, dolorem saepe sint totam architecto, vitae cum doloribus
    illo?
    Laudantium laboriosam saepe eligendi ipsam corrupti molestias expedita tempora eos minima, facere, eius dicta.
  </p>
  </section>
  <p className="breakout call-to-action">Magnam eius beatae similique officiis quaerat. Natus est dicta temporibus a. Aut mollitia magnam quo. Sequi
    repellendus voluptas quia suscipit autem iste! Accusamus, delectus beatae inventore obcaecati ut totam ab.</p>

  <p>Eligendi ea maiores asperiores sed nobis qui, animi sint? Ratione laboriosam cum quasi repudiandae in,
    necessitatibus veritatis nihil perferendis maiores consequuntur magnam perspiciatis minus est quibusdam,
    voluptate
    voluptas natus corporis.</p>
  <q className='quote'>Vero, facilis! Animi quam dolor a sunt, quae placeat perspiciatis quia odio quisquam at tempore dolorem eum
    ratione
    vel minima eaque repellendus dolores architecto necessitatibus velit reprehenderit amet et rem.</q>
  <p>Quos, animi expedita facere iste soluta accusamus et facilis, temporibus nemo praesentium iusto aliquam
    voluptas
    magnam pariatur blanditiis optio, corporis laborum quas? Quis libero aperiam est fuga veniam eos tenetur?</p>
  <section className="full-width bg-secondary section-padding flow">
    {/* <h3>Infinite Scroll Animation DEMO:</h3> */}
    <h3 className='animationTitle'>Анимация бесконечной прокрутки:</h3>
    <div className='scroller' data-animate={`${animateScroller === false ? false : true}`}>
      <ul className="infinite-list scroller__inner"  data-direction='left'>
      {
        infiniteList.map((item)=>{
          return <li>{item}</li>
        })
      }
      </ul>
      {/* <ul className="infinite-list scroller__inner" data-direction='right'>
      {
        infiniteList.map((item)=>{
          return <li>{item}</li>
        })
      }
      </ul> */}
    </div>
  </section>
  <section className="bg-primary full-width">
  <footer className='footerContainer'>
  <div className='footerIconContainer'>
    <svg width="45px" height="45px" className="logo" id="logo-10" viewBox="-6.34 0 94.461 94.461" xmlns="http://www.w3.org/2000/svg"><defs><style></style></defs><path d="M40.869 84.942L8.1 66.177V28.329h.046L0 23.615v47.231l40.869 23.615z" class="a"/><path d="M40.87 47.231L16.385 33.089h-.046v28.329l24.531 14.05z" class="a"/><path d="M81.784 23.615L40.869 0 0 23.615l8.146 4.714 32.723-18.9 32.814 18.854 8.1-4.668z" class="b"/><path d="M65.445 33.043l-24.53-14.142-24.531 14.187L40.869 47.23z" class="b"/><path d="M40.869 47.23l24.576-14.187zM73.683 28.283l8.1-4.668zM81.784 23.615l-8.1 4.668-8.238 4.76L40.869 47.23v28.238l24.576-14.1v-9.332l-16.476 9.379v-9.517l24.714-14.05v28.329L40.869 84.942v9.519l40.915-23.615z" class="c"/></svg>
  </div>
  <div  className='footerNavContainer'>
    <nav>
        <ul>
          <li><a href="#">Домой</a></li>
          <li><a href="#">О Нас</a></li>
          <li><a href="#">Блог</a></li>
          <li><a href="#">Контакты</a></li>
        </ul>
      </nav> 
   </div>
    </footer>
  </section>

</main>
</>
  );
}

export default App;
